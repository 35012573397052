import script from "./AtualizacaoCadastral.vue?vue&type=script&setup=true&lang=js"
export * from "./AtualizacaoCadastral.vue?vue&type=script&setup=true&lang=js"

const __exports__ = script;

export default __exports__
import QPage from 'quasar/src/components/page/QPage.js';
import QForm from 'quasar/src/components/form/QForm.js';
import QField from 'quasar/src/components/field/QField.js';
import QOptionGroup from 'quasar/src/components/option-group/QOptionGroup.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QPage,QForm,QField,QOptionGroup,QBtn});
