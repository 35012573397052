<script setup>
import { Head, useForm } from '@inertiajs/inertia-vue3';
import BlankLayout from '../../Layouts/BlankLayout';
import Icone from '../../Componentes/Global/Icone';
import useRotas from '../../Composables/useRotas';
import { useQuasar } from 'quasar';
import FormRow from '../../Componentes/Forms/FormRow';
import FormInput from '../../Componentes/Forms/FormInput';
import FormGroupLabel from '../../Componentes/Forms/FormGroupLabel';
import { cnpj } from 'cpf-cnpj-validator';
import isEmail from 'validator/lib/isEmail';
import {onMounted, ref} from "vue";

const $q = useQuasar();
const route = useRotas();

const listaEstadoUF = ref([]);
const listaInteresses = ref([]);

const atualizarListaEstadoUF = async () => {
    const response = await fetch(route('utilidades.listaEstadoUF'), {
        method: 'GET',
        credentials: 'include'
    });

    const lista = await response.json();

    lista.forEach(estado => {
        listaEstadoUF.value.push(estado);
    });

    console.log(listaEstadoUF.value);

}

const atualizarListaInteresses = async () => {
    const response = await fetch(route('utilidades.listaInteresses'), {
        method: 'GET',
        credentials: 'include'
    });

    const lista = await response.json();

    lista.forEach(interesse => {
        listaInteresses.value.push(interesse);
    });

}

onMounted(async() =>{
    await atualizarListaEstadoUF();
    await atualizarListaInteresses();
});

const form = useForm({
    nomeUsuario: null, // 'Teste Novo',
    senha: null, // '123456789Aa@',
    nomeFantasia: null, // 'Teste Novo',
    razaoSocial: null, // 'Teste Novo',
    cnpj: null, // '92378781000170',
    inscricaoEstadual: null, // '11111111111',
    nomeResponsavel: null, // 'Teste Novo',
    email: null, // 'teste@teste.com',
    celular: null, // '11954125910',
    telefone: null, // '11954125910',
    telefoneRecado: null, // '11954125910',
    cep: null, // '09667000',
    estado: null, // 'Teste Novo',
    cidade: null, // 'Teste Novo',
    bairro: null, // 'Teste Novo',
    endereco: null, // 'Teste Novo',
    numero: null, // 'Teste Novo',
    complemento: null, // 'Teste Novo',
    referencia: null, // 'Teste Novo',
    referenciaBancaria: null, // 'Teste Novo',
    informacoesExtras: null, // 'Teste Novo',
    interesses: [],
})

const campos = {
    tituloAcesso: {
        tipo: 'titulo',
        texto: 'Dados de Acesso'
    },
    nomeUsuario: {
        tipo: 'campo',
        largura: 6,
        label: 'Apelido',
        dica: 'Este será o nome que aparecerá no site para outros usuários, não pode ser um email',
        autocomplete: 'username',
        icon: 'mdi-account',
        required: true,
        regras: [
            val => val && !/(.com|@)/.test(val) || 'Este campo não pode ser um email.',
            val => val && !isEmail(val) || 'Este campo não pode ser um email',
            val => val && val.length > 5 || 'Deve ter mais de 5 caracteres'
        ]
    },
    senha: {
        tipo: 'campo',
        largura: 6,
        label: 'Senha',
        type: 'password',
        autocomplete: 'password',
        icon: 'mdi-key-variant',
        required: true,
        regras: [
            val => val && val.length >= 6 || 'Senha muito curta, ela deve ter 6 ou mais caracteres',
            val => val && /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])/.test(val) || 'A senha deve ter ao menos uma letra minuscula, uma maiuscula e um caractere especial'
        ]
    },
    tituloPessoais: {
        tipo: 'titulo',
        texto: 'Dados Pessoais'
    },
    nomeFantasia: {
        tipo: 'campo',
        largura: 6,
        label: 'Nome Fantasia',
        autocomplete: 'name',
        icon: 'mdi-factory',
        required: true,
        regras: []
    },
    razaoSocial: {
        tipo: 'campo',
        largura: 6,
        label: 'Razão Social',
        autocomplete: 'name',
        icon: 'mdi-factory',
        required: true,
        regras: []
    },
    cnpj: {
        tipo: 'campo',
        largura: 6,
        label: 'CNPJ',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        mascara: '##.###.###/####-##',
        regras: [
            val => val && val.length === 14 || 'Por favor, digite o seu CNPJ igual está no documento.',
            val => val && cnpj.isValid(val) || 'Este CNPJ parece estar incorreto, por favor, verifique para erros de digitação.',
        ]
    },
    inscricaoEstadual: {
        tipo: 'campo',
        largura: 6,
        label: 'Inscrição Estadual',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: false,
        regras: []
    },
    nomeResponsavel: {
        tipo: 'campo',
        largura: 6,
        label: 'Nome do Responsável',
        autocomplete: 'name',
        icon: 'mdi-account',
        required: true,
        regras: []
    },
    email: {
        tipo: 'campo',
        largura: 6,
        label: 'Email',
        autocomplete: 'email',
        icon: 'mdi-email',
        required: true,
        regras: [
            val => val && isEmail(val) || 'Por favor, preencha um email valido',
        ]
    },
    celular: {
        tipo: 'campo',
        largura: 6,
        label: 'DDD + Celular',
        autocomplete: 'off',
        icon: 'mdi-cellphone',
        required: true,
        regras: [
            val => ![
                '11 11111-1111',
                '22 22222-2222',
                '33 33333-3333',
                '44 44444-4444',
                '55 55555-5555',
                '66 66666-6666',
                '77 77777-7777',
                '88 88888-8888',
                '99 99999-9999',
                '12 34567-8910',
                '11 12345-6789',
                '12 33214-5665',
                '12 34567-8912'
            ].includes(val) || 'Telefone Inválido',
        ],
        mascara: '## #####-####'
    },
    telefone: {
        tipo: 'campo',
        largura: 6,
        label: 'DDD + Telefone Fixo',
        autocomplete: 'off',
        icon: 'mdi-phone',
        required: false,
        regras: [
            val => ![
                '11 11111-1111',
                '22 22222-2222',
                '33 33333-3333',
                '44 44444-4444',
                '55 55555-5555',
                '66 66666-6666',
                '77 77777-7777',
                '88 88888-8888',
                '99 99999-9999',
                '12 34567-8910',
                '11 12345-6789',
                '12 33214-5665',
                '12 34567-8912'
            ].includes(val) || 'Telefone Inválido',
        ],
        mascara: '## #####-####'
    },
    telefoneRecado: {
        tipo: 'campo',
        largura: 6,
        label: 'DDD + Telefone para recado',
        autocomplete: 'off',
        icon: 'mdi-phone-in-talk',
        required: false,
        regras: [
            val => ![
                '11 11111-1111',
                '22 22222-2222',
                '33 33333-3333',
                '44 44444-4444',
                '55 55555-5555',
                '66 66666-6666',
                '77 77777-7777',
                '88 88888-8888',
                '99 99999-9999',
                '12 34567-8910',
                '11 12345-6789',
                '12 33214-5665',
                '12 34567-8912'
            ].includes(val) || 'Telefone Inválido',
        ],
        mascara: '## #####-####'
    },
    tituloEndereco: {
        tipo: 'titulo',
        texto: 'Endereço'
    },
    cep: {
        tipo: 'campo',
        largura: 6,
        label: 'CEP',
        autocomplete: 'postcode',
        icon: 'mdi-map-search',
        required: true,
        mascara: '#####-###',
        regras: [
            val => val && val.length >= 5 || 'CEP Inválido',
        ],
        onChange: async () => {
            if (form.cep.length > 6){
                let resultado;
                try {
                    console.log(route('utilidades.consultaCep', {cep: form.cep}))
                    $q.loading.show({
                        message: 'Procurando seu CEP...'
                    });
                    resultado = await (await fetch(route('utilidades.consultaCep', {cep: form.cep}), {
                        method: 'GET',
                        credentials: 'include'
                    })).json();

                    $q.loading.hide();
                    form.endereco = (resultado.nome) ? `${resultado.tipo} ${resultado.nome}` : '';
                    form.bairro = resultado.bairro.nome;
                    form.cidade = resultado.bairro.cidade.nome;
                    //form.estado = resultado.bairro.cidade.estado.nome;
                    form.estado = resultado.bairro.cidade.estado.codigo_uf;
                } catch (e) {
                    $q.notify({
                        icon: 'mdi-map-marker-remove',
                        message: 'O CEP fornecido não foi encontrado',
                        color: 'negative'
                    })
                }
            }
        }
    },
    estado: {
        tipo: 'campo',
        largura: 6,
        label: 'Estado',
        autocomplete: 'off',
        icon: 'mdi-map',
        required: true,
        opcoes: listaEstadoUF.value,
        readonly: false,
        regras: []
    },
    cidade: {
        tipo: 'campo',
        largura: 6,
        label: 'Cidade',
        autocomplete: 'off',
        icon: 'mdi-city-variant',
        required: true,
        readonly: false,
        regras: [
            val => val && val.length <= 100 || 'Cidade deve ser abreviada',
        ]
    },
    bairro: {
        tipo: 'campo',
        largura: 6,
        label: 'Bairro',
        autocomplete: 'off',
        icon: 'mdi-home-map-marker',
        required: true,
        readonly: false,
        regras: [
            val => val && val.length <= 100 || 'Bairro deve ser abreviado',
        ]
    },
    endereco: {
        tipo: 'campo',
        largura: 4,
        label: 'Rua',
        autocomplete: 'off',
        icon: 'mdi-map-marker-multiple',
        required: true,
        regras: [
            val => val && val.length <= 150 || 'Endereço deve ser abreviado',
        ]
    },
    numero: {
        tipo: 'campo',
        largura: 4,
        label: 'Número',
        autocomplete: 'off',
        icon: 'mdi-pin',
        required: true,
        regras: []
    },
    complemento: {
        tipo: 'campo',
        largura: 4,
        label: 'Complemento',
        autocomplete: 'off',
        icon: 'mdi-pin',
        required: false,
        regras: []
    },
    tituloInteresses: {
        tipo: 'titulo',
        texto: 'Interesses'
    },
    interesses: {
        tipo: 'checkbox',
        largura: 6,
        label: 'Interesses',
        autocomplete: 'off',
        icon: 'mdi-map',
        required: true,
        opcoes: listaInteresses.value,
        readonly: false,
        regras: []
    },
    tituloExtra: {
        tipo: 'titulo',
        texto: 'Informações Extra'
    },
    // referencia: {
    //     tipo: 'campo',
    //     largura: 6,
    //     label: 'Senha',
    //     autocomplete: 'password',
    //     icon: 'mdi-key-variant',
    //     required: true,
    //     regras: [
    //         val => val && val.length >= 6 || 'Senha muito curta, ela deve ter 6 ou mais caracteres',
    //         val => val && /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])/.test(val) || 'A senha deve ter ao menos uma letra minuscula, uma maiuscula e um caractere especial'
    //     ]
    // },
    // referenciaBancaria: {
    //     tipo: 'campo',
    //     largura: 6,
    //     label: 'Senha',
    //     autocomplete: 'password',
    //     icon: 'mdi-key-variant',
    //     required: true,
    //     regras: [
    //         val => val && val.length >= 6 || 'Senha muito curta, ela deve ter 6 ou mais caracteres',
    //         val => val && /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])/.test(val) || 'A senha deve ter ao menos uma letra minuscula, uma maiuscula e um caractere especial'
    //     ]
    // },
    informacoesExtras: {
        tipo: 'campo',
        largura: 12,
        label: 'Informações Extras',
        autocomplete: 'password',
        icon: 'mdi-file-document-multiple-outline',
        required: false,
        regras: []
    },
};
</script>

<template>
    <Head title="Documentação Pessoa Jurídica" />
    <BlankLayout>
        <q-page class="flex row justify-center items-start q-py-md">
            <div class="flex column items-center col-11">
                <icone height="7em" width="14em" />
                <h4
                    class="text-center text-primary font-weight-light q-ma-md q-mb-lg"
                    :class="$q.screen.gt.sm ? 'text-h4' : 'text-h5'">
                    Cadastro de pessoa jurídica
                </h4>
                <q-form class="full-width" @submit="form.post(route('cadastro.juridica.processar'))">
                    <form-row>
                        <template :key="indice" v-for="(config, campo, indice) in campos">
                            <template v-if="config.condicional === undefined || config.condicional.value">
                                <template v-if="config.tipo === 'titulo'">
                                    <form-group-label :text="config.texto" />
                                </template>
                                <template v-if="config.tipo === 'checkbox'">
                                    <q-field
                                        v-model="form[campo]"
                                        borderless
                                        dense
                                        :rules="[ val => +val?.length > 0 || '']"
                                    >
                                        <q-option-group
                                            v-model="form[campo]"
                                            :options="config.opcoes"
                                            color="primary"
                                            type="checkbox"
                                            inline
                                            class="q-mx-none q-mb-md full-width"
                                            size="lg"
                                        />
                                    </q-field>
                                </template>
                                <template v-if="config.tipo === 'campo'">
                                    <form-input
                                        v-model="form[campo]"
                                        :autocomplete="config.autocomplete"
                                        :label="config.label"
                                        :rules="config.regras"
                                        :type="config.type"
                                        :icon="config.icon"
                                        :required="config.required"
                                        :largura="config.largura"
                                        :mascara="config.mascara"
                                        :opcoes="config.opcoes"
                                        :data="config.data"
                                        :readonly="config.readonly"
                                        :optionsFn="config.optionsFn"
                                        :dataDefaultYM="config.dataDefaultYM"
                                        @change="config.onChange"
                                    />
                                </template>
                            </template>
                        </template>
                    </form-row>
                    <q-btn
                        class="full-width"
                        color="primary"
                        label="Prosseguir"
                        icon-right="mdi-arrow-right"
                        type="submit"
                        :loading="form.processing"
                    />
                </q-form>
            </div>
        </q-page>
    </BlankLayout>
</template>
