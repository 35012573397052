<script setup>
import {defineProps, ref, computed, onMounted} from 'vue';
import useUtils from '../Composables/useUtils';
import { useQuasar } from 'quasar';
import GoogleMap from '../Componentes/GoogleMap';
import HabilitacaoExtra from '../Componentes/Leilao/HabilitacaoExtra';
import useRotas from '../Composables/useRotas';
import Lance from '../Componentes/Leilao/Lance';
import useLote from '../Composables/useLote';
import useGlobals from '../Composables/useGlobals';
import useLoteLocalComUpdates from '../Composables/useLoteLocalComUpdates';
import LeilaoLayout from '../Layouts/LeilaoLayout';
import {useForm, usePage} from '@inertiajs/inertia-vue3';
import { Inertia } from '@inertiajs/inertia';
import useLeilaoLocalComUpdates from "../Composables/useLeilaoLocalComUpdates";

const props = defineProps({
    loteInit: Object,
    habilitado: Boolean,
    habilitacoesLeilao: Array,
    habilitacoesExtraLeilao: Array,
    idsHabilitacoesClienteAprovadas: Array,
    idsHabilitacoesClienteVerificando: Array,
    idsHabilitacoesClienteReprovadas: Array,
    idsHabilitacoesCliente: Array,
    statusHabilitacaoLeilao: Object,
    idsHabilitacoesExtraClienteAprovadas: Array,
    idsHabilitacoesExtraClienteVerificando: Array,
    idsHabilitacoesExtraClienteReprovadas: Array,
    idsHabilitacoesExtraCliente: Array,
    statusHabilitacaoExtraLeilao: Object,
    documentosRequeridos: Array,
    documentosRequeridosExtra: Array,
    lotesIndice: Array,
})

const {
    lote,
    leilao
} = useLoteLocalComUpdates(props);


const {
    leilao: leilaoSock
} = useLeilaoLocalComUpdates({
    leilaoInit: leilao
})

const $q = useQuasar();

const route = useRotas();

const {
    getImagem,
    formatDate,
    formatador,
    formatadorLote,
    baixar,
    nomeStatusLote,
    urlTransmisao
} = useUtils();

const {
    logoLeiloeiro,
    urlAtual,
    logado,
    emAnalise,
    usernameLogado
} = useGlobals();

const {
    timer,
    slide,
    tab,
    edital,
    outrosDocumentos,
    vistoria,
    share,
    lanceUsuario,
    lanceData,
    lanceProprio,
    verLances,
    verDocumentos,
    imprimir,
    timerModerador,
    link_youtube,
    // lanceAtualAutomatico
} = useLote(leilao, lote)

const habilitandoLocal = ref(false);
const lanceLocal = ref(false);
const lanceLocalAutomatico = ref(false);
const lanceAutomatico = ref([]);
const temLanceAtomatico = ref(false);

const solicitarLaudo = () => {
    window.open(route('lote.laudo', {
        idLeilao: props.loteInit.id_leilao,
        idLote: props.loteInit.id
    }), '_blank');
}

const doulheTresConfig = computed(() => window.doulheTresConfig);

const encaminharWhatsapp = () => {

    const linkWhatsapp = `https://api.whatsapp.com/send/?phone=${doulheTresConfig.value.numero_whatsapp_contato_imobiliario}&text=Ol%c3%a1%2c+gostaria+de+conversar+sobre+o+lote+${lote.value.sequencia}+do+leil%c3%a3o+${leilao.value.id}.&type=phone_number&app_absent=1`;
    window.open(linkWhatsapp, '_blank');

}

const lotesSeletor = computed(() => usePage().props.value.lotesIndice || []);
const opcoesSeletor = computed(
    () => lotesSeletor.value.map(
        (l) => ({ label: formatadorLote(l.sequencia), value: l.id })
    )
);

const irParaLote = (id) => {
    Inertia.visit(route('lote', {
        idLeilao: leilao.value.id,
        idLote: id
    }))
}

const offsetLote = (offset) => {
    if (lotesSeletor.value[lotesSeletor.value.findIndex((l) => +l.id === +lote.value.id)+offset].id){
        Inertia.visit(route('lote', {
            idLeilao: leilao.value.id,
            idLote: lotesSeletor.value[lotesSeletor.value.findIndex((l) => +l.id === +lote.value.id)+offset].id
        }), {
            replace: true
        })
    }
}

const temAnterior = computed(() => !!lotesSeletor.value[lotesSeletor.value.findIndex((l) => +l.id === +lote.value.id)-1])
const temProximo = computed(() => !!lotesSeletor.value[lotesSeletor.value.findIndex((l) => +l.id === +lote.value.id)+1])

const loteSeletor = computed({
    get: () => ({ label: formatadorLote(lote.value.sequencia), value: lote.id }),
    set: (value) => irParaLote(value.value),
});

const urlVideo = computed(() => {
    return urlTransmisao(leilao);
});

const atualizarLanceAutomatico = async () => {
    lanceAutomatico.value = await (await fetch(route('auditorio.temLanceAutomatico', {idLote: lote.value.id}))).json();
    temLanceAtomatico.value = lanceAutomatico.value.tem_lance_automatico;
}

onMounted(async() =>{
    if(logado.value){
        await atualizarLanceAutomatico();
    }
});

const desabilitaBtnProposta = ref(false);
const formProposta = useForm({
    vendaDireta: leilao?.value.id,
    idLote: lote?.value.id,
    lote: lote?.value.sequencia,
    loteTitulo: lote?.value.titulo,
    valorProposta: null,
});

const onSubmitProposta = () => {
    formProposta.post(route('lote.proposta.enviar'));
    desabilitaBtnProposta.value = true
}


const decodeHtmlEntities = (text) => {
    const entities = {
        '&lt;': '<',
        '&gt;': '>',
        '&quot;': '"',
        '&amp;': '&'
    };
    return text.replace(/&lt;|&gt;|&quot;|&amp;/g, match => entities[match]);
}
const replaceHmtlIframe = (encodedHtml) => {

    let decodedHtml = encodedHtml;

    if (decodedHtml && decodedHtml.length > 0) {

        const arrayRegex = [/&lt;iframe\s+src="https:\/\/www\.google\.com\/maps\/embed[^"]*"[^&]*&gt;&lt;\/iframe&gt;/g,
            /&lt;iframe[^>]*\bsrc="https:\/\/www\.youtube\.com\/embed\/[^"]*"[^>]*&gt;&lt;\/iframe&gt;/g
        ];

        arrayRegex.forEach((e) => {
            decodedHtml = decodedHtml.replace(e, match => decodeHtmlEntities(match))
        });

    }

    return decodedHtml;
}

const descricaoLote = ref(replaceHmtlIframe(lote?.value.descricao));


</script>

<template>
    <LeilaoLayout :title="formatadorLote(lote.sequencia) + ' - ' + lote.titulo" :description="lote.descricao">
        <q-page>
            <div class="flex row full-width">
                <div class="flex column col-12 col-md-6">
                    <q-responsive class="full-width" :ratio="16/9">
                        <div :style="
                            `position: absolute;
                            top: 0px;
                            width: 100%!important;
                            height: 40px!important;
                            z-index: 998;
                            background-color: ${lote.status_lote.cor_site};`
                        ">
                            <p style="color: white; font-size: 25px;font-weight: 300;text-align: center;">{{ nomeStatusLote(lote.status_lote, +leilao.acao_ao_atingir_metrica ) }}</p>
                        </div>
                        <q-carousel
                            v-model="slide"
                            animated
                            arrows
                            control-color="white"
                            control-text-color="primary"
                            control-type="push"
                            next-icon="mdi-chevron-right"
                            prev-icon="mdi-chevron-left"
                            infinite
                            navigation
                            style="height: 27em;"
                        >
                            <q-carousel-slide
                                v-if="+leilaoSock.transmissao_iniciada === 1 && +urlVideo?.exibir_tansmissao_fora_auditorio === 1"
                                :name="1"
                                class="q-pa-none"
                            >

                                <q-video
                                    class="absolute-full"
                                    :src="urlVideo.link_transmissao"
                                />
                                <q-carousel-control v-if="+leilaoSock.fechamento_automatico === 0 && lote.timer_moderador && timerModerador">
                                    <q-chip :color="lote.proximoStatus.cor" text-color="white">
                                        {{ timerModerador }}
                                    </q-chip>
                                </q-carousel-control>
                                <q-carousel-control v-else>
                                    <q-chip color="primary" text-color="white">
                                        AO VIVO
                                    </q-chip>
                                </q-carousel-control>
                            </q-carousel-slide>
                            <q-carousel-slide
                                v-for="(imagem, key) in (lote.imagens_lote && lote.imagens_lote.length > 0                ? lote.imagens_lote
                : (lote.leilao.comitente.arquivo_capa_padrao ? [{arquivo: lote.leilao.comitente.arquivo_capa_padrao}] : []))"
                                :key="key"
                                :name="((+leilaoSock.transmissao_iniciada === 1 && +urlVideo?.exibir_tansmissao_fora_auditorio === 1)?2:1)+key"
                                class="q-pa-none">
                                <q-img
                                    class="full-width full-height"
                                    loading="lazy"
                                    :src="getImagem(imagem?.arquivo, true, lote.status_lote?.nome)"
                                    :ratio="16/9"
                                >
                                    <template v-slot:error>
                                        <div class="absolute-full flex flex-center bg-primary text-white">
                                            Houve um problema carregando a imagem
                                        </div>
                                    </template>
                                    <template v-slot:loading>
                                        <div class="absolute-full flex column flex-center bg-white text-primary">
                                            <img :src="logoLeiloeiro" style="width: 40%" />
                                            <p class="q-mt-sm">
                                                <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                                            </p>
                                        </div>
                                    </template>
                                </q-img>
                                <div class="absolute-bottom seq-caption q-pb-xl">
                                    <h5>{{formatadorLote(lote.sequencia)}}</h5>
                                </div>
                                <q-carousel-control v-if="+leilao.fechamento_automatico === 1 && lote.proximoStatus.timestamp">
                                    <q-chip :color="lote.proximoStatus.cor" text-color="white">
                                        {{lote.proximoStatus.texto}},  {{timer}}
                                    </q-chip>
                                </q-carousel-control>
                                <q-carousel-control v-if="+leilao.fechamento_automatico === 0 && lote.timer_moderador && timerModerador">
                                    <q-chip :color="lote.proximoStatus.cor" text-color="white">
                                        {{ timerModerador }}
                                    </q-chip>
                                </q-carousel-control>
                            </q-carousel-slide>
                        </q-carousel>
                    </q-responsive>
                    <div v-if="$q.screen.gt.sm" class="flex row justify-around q-ma-sm full-width">
                        <div class="row justify-evenly q-pt-xs full-width">
                            <q-card-section v-if="edital"
                                @click="baixar(edital.arquivo.signedUrl)"
                                class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Edital
                                </h6>
                            </q-card-section>
                            <q-separator v-if="edital" inset vertical/>
                            <q-card-section v-if="vistoria"
                                @click="baixar(vistoria.arquivo.signedUrl)"
                                class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-download"
                                    size="2.5em"
                                    style="flex: 1"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Vistoria
                                </h6>
                            </q-card-section>
                            <q-separator v-if="vistoria" inset vertical/>
                            <q-card-section v-if="outrosDocumentos"
                                @click="verDocumentos = true"
                                class="flex column items-center"
                            >
                                <q-icon
                                    color="primary"
                                    class="cursor-pointer"
                                    name="mdi-file-document-multiple"
                                    size="2.5em"
                                >
                                </q-icon>
                                <h6 class="q-my-none full-width cursor-pointer">
                                    Todos
                                </h6>
                            </q-card-section>
                        </div>
                    </div>
                </div>
                <div class="flex column col-12 col-md-6">
                    <div class="flex row justify-center q-px-md q-pt-md q-pb-none" style="flex: 1" v-if="$q.screen.lt.md">
                        <q-btn
                            v-if="temAnterior"
                            stretch size="sm" color="primary" @click="offsetLote(-1)" icon="mdi-menu-left">
                            Anterior
                        </q-btn>
                        <q-select
                            outlined
                            dense
                            bg-color="white"
                            color="secondary"
                            label-color="primary"
                            v-model="loteSeletor"
                            :options="opcoesSeletor"
                            class="q-mx-sm"/>
                        <q-btn
                            v-if="temProximo"
                            stretch size="sm" color="primary" @click="offsetLote(1)" icon-right="mdi-menu-right">
                            Próximo
                        </q-btn>
                    </div>
                    <div class="flex row items-center q-pa-none full-width wrap" style="justify-content: space-between;">
                        <div class="flex row items-center q-px-md q-pt-md q-pb-none" style="flex: 1">
                            <span class="text-primary q-pr-sm">
                                Em {{formatDate(lote.leilao.data_hora_inicio)}}
                            </span>
                            <span class="text-primary q-px-sm"
                                  style="border: 1px solid #1E4A7D; border-radius: 5px">
                             {{lote.leilao?.localidade?.nome|| 'ONLINE'}}
                            </span>
                            <q-img
                                :src="lote.leilao?.comitente?.arquivo?.logoComitenteUrl"
                                height="35px"
                                width="100px"
                                fit="contain">
                            </q-img>
                        </div>
                        <div class="flex row justify-end q-px-md q-pt-md q-pb-none"  style="flex: 1" v-if="$q.screen.gt.sm">
                            <q-btn
                                v-if="temAnterior" color="primary" @click="offsetLote(-1)" icon="mdi-menu-left">

                                <template v-if="$q.screen.gt.md && $q.screen.lt.xl">Anterior</template>
                                <template v-else-if="$q.screen.gt.lg">Lote Anterior</template>

                            </q-btn>
                            <q-select
                                dense
                                bg-color="white"
                                color="secondary"
                                label-color="primary"
                                v-model="loteSeletor"
                                :options="opcoesSeletor"
                                class="q-mx-sm"/>
                            <q-btn
                                v-if="temProximo" color="primary" @click="offsetLote(1)" icon-right="mdi-menu-right">

                                <template v-if="$q.screen.gt.md && $q.screen.lt.xl">Próximo</template>
                                <template v-else-if="$q.screen.gt.lg">Próximo Lote</template>

                            </q-btn>
                        </div>
                    </div>
                    <div class="flex column q-px-md q-pt-none">
                        <h4 class="q-ma-none text-primary">
                            {{formatadorLote(lote.sequencia)}} - {{ lote.titulo }}
                        </h4>
                        <div>
                            <q-list>

                                <q-item v-if="!(lote.lanceAtual > 0)" clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="positive" size="lg" name="mdi-cash"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <template v-if="+lote.leilao.venda_direta === 1">
                                            <q-item-label overline>Valor Referência</q-item-label>
                                        </template>
                                        <template v-else>
                                            <q-item-label overline>Lance inicial</q-item-label>
                                        </template>
                                        <q-item-label class="text-h4 text-positive">
                                            {{ formatador.format((+lote.leilao.primeiro_leilao_concluido === 1 && +lote.leilao.dois_leiloes === 1) ? lote.lance_inicial_segundo_leilao : lote.lance_inicial) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item v-else clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon  size="lg" color="positive" name="mdi-cash"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label overline>
                                            <template v-if="+lote.leilao.venda_direta === 1">
                                                Oferta Atual
                                            </template>
                                            <template v-else>
                                                Lance Atual
                                            </template>
                                        </q-item-label>
                                        <q-item-label  class="text-h4 text-positive">
                                            {{ formatador.format(lote.lanceAtual) }}
                                        </q-item-label>
                                        <q-item-label overline>
                                            por <b :class="(lanceProprio) ? 'text-positive' : 'text-primary'">{{lanceUsuario}}</b> em <b>{{lanceData}}</b>
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item clickable v-if="+lote.leilao.venda_direta === 0">
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-cash-plus"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label overline>Incremento</q-item-label>
                                        <q-item-label  class="text-h6 text-primary">
                                            {{ formatador.format(lote.incrementoLote) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item clickable v-if="+lote.leilao.venda_direta === 0">
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-cash-fast"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label overline>
                                            <template v-if="+lote.leilao.venda_direta === 1">
                                                Próxima Oferta
                                            </template>
                                            <template v-else>
                                                Próximo Lance
                                            </template>
                                        </q-item-label>
                                        <q-item-label  class="text-h6 text-primary">
                                            {{ formatador.format(lote.proximoLance) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>


                                <q-item v-if="+lote.leilao.venda_direta === 1"
                                >
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-currency-usd"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Valores de Referência
                                        </q-item-label>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Oferta Inicial: {{ formatador.format(lote.lance_inicial) }}
                                        </q-item-label>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Incremento: {{ formatador.format(lote.incremento) }}
                                        </q-item-label>
                                        <q-item-label v-if="lote.leilao.exibir_avaliacao_empresa === '1' && lote.avaliacao_comitente" :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Valor Avaliado: {{ formatador.format(lote.avaliacao_comitente) }}
                                        </q-item-label>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Próxima Oferta: {{ formatador.format(lote.proximoLance) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>



                                <q-item clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-calendar"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-tooltip>
                                            A primeira praça deste leilão acontecerá em {{
                                                formatDate(lote.leilao?.data_hora_inicio)
                                            }}
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            <template v-if="+lote.leilao.venda_direta === 1">
                                            Data Encerramento do Evento
                                            </template>
                                            <template v-else>
                                                1º Leilão
                                            </template>
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">{{
                                                formatDate(lote.leilao?.data_hora_inicio)
                                            }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>


                                <q-item v-if="+lote.leilao.venda_direta === 1 && lote.vistoria_veicular" clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-car"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-tooltip>
                                            Marca
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Marca
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.modelo?.marca?.nome}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-tooltip>
                                            Modelo
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Modelo
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.modelo?.nome}}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item v-if="+lote.leilao.venda_direta === 1 && lote.vistoria_veicular" clickable>
                                    <q-item-section avatar class="items-center">
                                    </q-item-section>

                                    <q-item-section>
                                        <q-tooltip>
                                            Ano Fabricação / Modelo
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Ano Fabricação / Modelo
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.ano_fabricacao}} / {{lote?.vistoria_veicular?.ano_modelo}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-tooltip>
                                            Cor
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Cor
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.cor}}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item v-if="+lote.leilao.venda_direta === 1 && lote.vistoria_veicular" clickable>
                                    <q-item-section avatar class="items-center">

                                    </q-item-section>

                                    <q-item-section>
                                        <q-tooltip>
                                            Final Placa
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Final Placa
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.placa}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-tooltip>
                                            Chassi
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Chassi
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.chassi}}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item v-if="+lote.leilao.venda_direta === 1 && lote.vistoria_veicular" clickable>
                                    <q-item-section avatar class="items-center">

                                    </q-item-section>

                                    <q-item-section>
                                        <q-tooltip>
                                            Km
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Km
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.kilometragem}}
                                        </q-item-label>
                                    </q-item-section>
                                    <q-item-section v-if="lote?.vistoria_veicular?.blindado">
                                        <q-tooltip>
                                            Blindado?
                                        </q-tooltip>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>
                                            Blindado?
                                        </q-item-label>
                                        <q-item-label  :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{lote?.vistoria_veicular?.blindado}}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>

                                <q-item v-if="+lote.leilao.venda_direta === 0"
                                >
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="positive" name="mdi-currency-usd"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" >Valores do <template v-if="lote.leilao.dois_leiloes === '1'">1º</template> Leilão</q-item-label>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Lance Inicial {{ formatador.format(lote.lance_inicial) }}
                                        </q-item-label>
                                        <q-item-label :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Incremento {{ formatador.format(lote.incremento) }}
                                        </q-item-label>
                                        <q-item-label v-if="lote.leilao.exibir_avaliacao_empresa === '1' && lote.avaliacao_comitente" :style="+lote.leilao.primeiro_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Avaliação {{ formatador.format(lote.avaliacao_comitente) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item v-if="lote.leilao.dois_leiloes === '1'"
                                        clickable>
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="primary" name="mdi-calendar"/>
                                    </q-item-section>
                                    <q-item-section>
                                        <q-tooltip >
                                            A segunda praça deste leilão acontecerá em {{
                                                formatDate(lote.leilao?.data_hora_inicio_segundo_leilao)
                                            }}
                                        </q-tooltip>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" overline>2º Leilão</q-item-label>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''">
                                            {{ formatDate(lote.leilao?.data_hora_inicio_segundo_leilao) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                                <q-item v-if="lote.leilao.dois_leiloes === '1' && +lote.leilao.venda_direta === 0"
                                    clickable
                                >
                                    <q-item-section avatar class="items-center">
                                        <q-icon color="positive" name="mdi-currency-usd"/>
                                    </q-item-section>

                                    <q-item-section>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" >Valores do 2º Leilão</q-item-label>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Lance Inicial {{ formatador.format(lote.lance_inicial_segundo_leilao) }}
                                        </q-item-label>
                                        <q-item-label :style="+lote.leilao.segundo_leilao_concluido === 1 ? 'text-decoration: line-through' : ''" caption>
                                            Incremento {{ formatador.format(lote.incremento_segundo_leilao) }}
                                        </q-item-label>
                                    </q-item-section>
                                </q-item>
                            </q-list>
                        </div>
                        <div class="flex column q-mt-md">

                            <q-btn
                                v-if="!logado"
                                @click="$inertia.visit(route('auth.login', {
                                    _query: {
                                        intended: urlAtual
                                    }
                                }))"
                                :class="'bg-primary'"
                                class="text-white full-width hide-print"
                                :label="(+lote.leilao.venda_direta === 1) ? 'Entre para enviar sua proposta!' : 'Entre para dar seu lance!'"
                            />
                            <q-btn
                                v-else-if="emAnalise"
                                disable
                                :class="'bg-warning'"
                                class="text-white full-width hide-print"
                                label="O seu cadastro está em analise"
                            />
                            <q-btn
                                v-else-if="!habilitado"
                                @click="habilitandoLocal = true"
                                :class="'bg-primary'"
                                class="text-white full-width hide-print"
                                :label="(+lote.leilao.venda_direta === 1) ? 'Habilite-se para fazer sua proposta!' : 'Habilite-se para dar seu lance!'"
                            />
                            <q-btn
                                v-else-if="!(['ABERTO', 'EM_PREGAO'].includes(lote.leilao?.status_leilao?.identificador))"
                                disable
                                :class="'bg-primary'"
                                class="text-white full-width hide-print"
                                :label="(+lote.leilao.venda_direta === 1) ? 'O Evento está ' + lote.leilao?.status_leilao?.nome : 'O Leilão está ' + lote.leilao?.status_leilao?.nome"
                            />
                            <q-btn
                                v-else-if="lote.status_lote.identificador === 'ABERTO'
                                            && !lanceProprio"
                                @click="lanceLocal = true"
                                :class="'bg-positive'"
                                class="text-white full-width hide-print"
                                :label="(+lote.leilao.venda_direta === 1) ? 'Enviar Proposta' : 'De Seu Lance!'"
                            />

                            <q-btn
                                v-else-if="lote.status_lote.identificador === 'ABERTO'"
                                disable
                                :class="'bg-positive'"
                                class="text-white full-width hide-print"
                                :label="(+lote.leilao.venda_direta === 1) ? 'Sua oferta está vencendo' : 'O seu lance está vencendo'"
                            />
                            <div class="flex row content-stretch items-stretch full-width">
                                <div class="flex column q-pr-sm"
                                     v-if="logado
                                        && habilitado
                                        && !emAnalise
                                        && lote.status_lote.identificador === 'ABERTO'
                                        && (['ABERTO', 'EM_PREGAO'].includes(lote.leilao.status_leilao.identificador))
                                        && +lote.leilao?.permitir_lance_automatico === 1"
                                     style="flex: 1">
                                    <q-btn
                                        class="full-width q-mt-sm bg-primary hide-print"
                                        @click="lanceLocalAutomatico = true"
                                        color="white"
                                        text-color="primary"
                                        :label="(temLanceAtomatico) ? (+lote.leilao.venda_direta === 1) ? 'Alterar Proposta Automática':'Alterar Lance Automático'
                                                                    : (+lote.leilao.venda_direta === 1) ? 'Configurar Proposta Automática' : 'Configurar Lance Automático'"
                                    />
                                </div>
                                <div class="flex column"
                                     :class="lote.leilao.status_leilao.identificador !== 'EM_PREGAO' ? '' : ''"
                                     style="flex: 1">
                                    <q-btn
                                        @click="verLances = true"
                                        class="text-white full-width q-mt-sm bg-primary hide-print"
                                        color="primary"
                                        :label="(+lote.leilao.venda_direta === 1) ? 'Ver todas as Propostas' : 'Ver todos os Lances'"
                                    />
                                </div>
                            </div>

                            <q-btn
                                v-if="lote?.id_vistoria_imobiliaria && !['ENCERRADO'].includes(lote.leilao?.status_leilao?.identificador)"
                                @click="encaminharWhatsapp"
                                class="text-white full-width q-mt-sm bg-negative hide-print"
                                color="primary"
                                label="Converse sobre este lote"
                            />

                            <q-btn
                                v-if="logado && +lote.leilao.venda_direta === 0 && !['ENCERRADO'].includes(lote.leilao?.status_leilao?.identificador)"
                                :class="'bg-positive q-mt-sm'"
                                @click="$inertia.visit(route('auditorio.lote', {idLeilao: lote.leilao.id, idLote: lote.id}))"
                                class="text-white full-width hide-print"
                                label="Acessar Auditório"
                            />
                            <q-btn
                                v-show="false"
                                class="text-white full-width q-mt-sm bg-primary hide-print"
                                label="Agendar Visitação"
                            />
                            <div class="row justify-evenly q-pt-xs">
                                <q-card-section @click="solicitarLaudo" v-if="false && +lote.tipo_lote === 2" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        :class="logado ? 'cursor-pointer' : 'cursor-not-allowed disabled'"
                                        name="mdi-file-lock-open"
                                        size="2.5em"
                                    >
                                        <q-tooltip v-if="!logado">
                                            Você deve estar logado para adquirir um laudo.
                                        </q-tooltip>
                                    </q-icon>
                                    Comprar laudo veicular
                                </q-card-section>
                                <q-separator @click="solicitarLaudo" v-if="false && +lote.tipo_lote === 2" inset vertical/>
                                <q-card-section @click="imprimir" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        class="cursor-pointer"
                                        name="mdi-printer"
                                        size="2.5em"
                                    >
                                    </q-icon>
                                    imprimir página
                                </q-card-section>
                                <q-separator inset vertical/>
                                <q-card-section @click="share = true" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        class="cursor-pointer"
                                        name="mdi-share-variant"
                                        size="2.5em"
                                    >
                                    </q-icon>
                                    Compartilhar lote
                                    <q-dialog  v-model="share">
                                        <q-card class="q-pa-md flex column">
                                            <ShareNetwork
                                                class="q-my-sm full-width"
                                                network="facebook"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                style="text-decoration: none;"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    style="text-decoration: none;"
                                                    color="primary"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-facebook"
                                                    label="Compartilhar no Facebook" />
                                            </ShareNetwork>
                                            <ShareNetwork
                                                class="q-my-sm full-width"
                                                network="twitter"
                                                style="text-decoration: none;"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    color="info"
                                                    style="text-decoration: none;"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-twitter"
                                                    label="Compartilhar no Twitter" />
                                            </ShareNetwork>
                                            <ShareNetwork
                                                style="text-decoration: none;"
                                                class="q-my-sm full-width"
                                                network="whatsapp"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    style="text-decoration: none;"
                                                    color="positive"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-whatsapp"
                                                    label="Compartilhar no Whatsapp" />
                                            </ShareNetwork>
                                        </q-card>
                                    </q-dialog>
                                </q-card-section>
                            </div>
                        </div>
                        <div class="flex column q-mt-md" v-if="1 === 2" >
                            <q-btn
                                v-if="!logado"
                                @click="$inertia.visit(route('auth.login', {
                                        _query: {
                                            intended: urlAtual
                                        }
                                    }))"
                                :class="'bg-primary'"
                                class="text-white full-width hide-print"
                                label="Entre para enviar sua proposta!"
                            />
                            <q-form  v-if="logado && +usePage().props.value.auth?.cliente?.tipo_pessoa === 1"  @submit="onSubmitProposta">
                                <q-input  class="text-h5"
                                         v-model="formProposta.valorProposta"
                                         filled
                                         label="Digite aqui o valor e envie sua proposta"
                                         prefix="R$"
                                         mask="###.###.###.###,##"
                                         reverse-fill-mask
                                         input-class="text-right"
                                         :disable="desabilitaBtnProposta === true"
                                         lazy-rules
                                         :rules="[ val => val && val.length > 0 || 'Por favor, digite um valor']"
                                >
                                </q-input>
                                <q-btn
                                    v-if="logado"
                                    :class="'bg-positive q-mt-sm'"
                                    :disable="desabilitaBtnProposta === true"
                                    type="submit"
                                    class="text-white full-width hide-print"
                                    label="Enviar Proposta"
                                />
                            </q-form>
                            <q-btn
                                v-if="logado && +usePage().props.value.auth?.cliente?.tipo_pessoa === 0"
                                :class="'bg-positive q-mt-sm'"
                                disabled
                                type="submit"
                                class="text-white full-width hide-print"
                                label="Necessário ser empresa e seguir as Regras de Participação"
                            />

                            <div class="row justify-evenly q-pt-xs">
                                <q-card-section @click="imprimir" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        class="cursor-pointer"
                                        name="mdi-printer"
                                        size="2.5em"
                                    >
                                    </q-icon>
                                    imprimir página
                                </q-card-section>
                                <q-separator inset vertical/>
                                <q-card-section @click="share = true" class="flex column items-center">
                                    <q-icon
                                        color="primary"
                                        class="cursor-pointer"
                                        name="mdi-share-variant"
                                        size="2.5em"
                                    >
                                    </q-icon>
                                    Compartilhar lote
                                    <q-dialog  v-model="share">
                                        <q-card class="q-pa-md flex column">
                                            <ShareNetwork
                                                class="q-my-sm full-width"
                                                network="facebook"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                style="text-decoration: none;"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    style="text-decoration: none;"
                                                    color="primary"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-facebook"
                                                    label="Compartilhar no Facebook" />
                                            </ShareNetwork>
                                            <ShareNetwork
                                                class="q-my-sm full-width"
                                                network="twitter"
                                                style="text-decoration: none;"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    color="info"
                                                    style="text-decoration: none;"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-twitter"
                                                    label="Compartilhar no Twitter" />
                                            </ShareNetwork>
                                            <ShareNetwork
                                                style="text-decoration: none;"
                                                class="q-my-sm full-width"
                                                network="whatsapp"
                                                :url="urlAtual"
                                                :title="lote.titulo"
                                                :description="lote.descricao || ''"
                                                :quote="lote.titulo"
                                                hashtags="satoleiloes"
                                            >
                                                <q-btn
                                                    style="text-decoration: none;"
                                                    color="positive"
                                                    class="text-decoration-none full-width"
                                                    icon-right="mdi-whatsapp"
                                                    label="Compartilhar no Whatsapp" />
                                            </ShareNetwork>
                                        </q-card>
                                    </q-dialog>
                                </q-card-section>
                            </div>
                        </div>


                    </div>
                </div>
                <div v-if="$q.screen.lt.md" class="flex row justify-around q-ma-sm full-width">
                    <div class="row justify-evenly q-pt-xs full-width">
                        <q-card-section v-if="edital"
                            @click="baixar(edital.arquivo.signedUrl)"
                            class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Edital
                            </h6>
                        </q-card-section>
                        <q-separator v-if="edital" inset vertical/>
                        <q-card-section v-if="vistoria"
                            @click="baixar(vistoria.arquivo.signedUrl)"
                            class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-download"
                                size="2.5em"
                                style="flex: 1"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Vistoria
                            </h6>
                        </q-card-section>
                        <q-separator v-if="vistoria" inset vertical/>
                        <q-card-section v-if="outrosDocumentos"
                            @click="verDocumentos = true"
                            class="flex column items-center"
                        >
                            <q-icon
                                color="primary"
                                class="cursor-pointer"
                                name="mdi-file-document-multiple"
                                size="2.5em"
                            >
                            </q-icon>
                            <h6 class="q-my-none full-width cursor-pointer">
                                Todos
                            </h6>
                        </q-card-section>
                    </div>
                </div>
            </div>
            <q-separator class="q-mb-lg"/>
            <div class="flex row full-width">
                <h2 class="full-width text-center q-mt-sm text-h4 q-px-md">
                    Detalhes do Lote - {{ lote.titulo }} </h2>
            </div>

            <div v-html="descricaoLote" class="q-mx-md"></div>
            <q-separator class="q-my-lg"/>

            <div class="flex row full-width">
                <q-expansion-item class="text-center full-width bg-grey-2 q-mx-sm" label="Condições de Venda">
                    <q-card class="q-pa-sm" bordered>
                        <h6 class="no-margin no-padding text-center">Condições de Venda</h6>
                        <div style="max-height: " v-html="lote?.leilao.descricao_do_pagamento"></div>
                    </q-card>
                </q-expansion-item>
            </div>
            <div v-show="false" class="flex row full-width">
                <div class="flex column items-center"
                     style="flex:1;">
                    <q-icon
                        class="cursor-pointer"
                        color="primary"
                        name="mdi-calendar-month"
                        size="2.5em"
                    ></q-icon>
                    <span>Ano</span>
                    <span class="text-secondary">2021</span>
                </div>
                <div class="flex column items-center"
                     style="flex:1;">
                    <q-icon
                        class="cursor-pointer"
                        color="primary"
                        name="mdi-gas-station"
                        size="2.5em"></q-icon>
                    <span>Tipo de combustível</span>
                    <span class="text-secondary">Gasolina</span>
                </div>
                <div class="flex column items-center"
                     style="flex:1;">
                    <q-icon
                        class="cursor-pointer"
                        color="primary"
                        name="mdi-car-settings"
                        size="2.5em"></q-icon>
                    <span>Classificação</span>
                    <span class="text-secondary">Automóveis</span>
                </div>
            </div>
            <div class="flex row full-width">
                <q-list class="full-width">
                    <q-expansion-item
                        class="text-center bg-grey-2 q-mt-md"
                        label="Video, Imagens e Panoramas"
                        :default-opened="(link_youtube)"
                    >
                        <q-separator/>
                        <div :style="$q.screen.lt.sm ? 'width: 100vw' : 'width: 100%'" class="q-gutter-y-md">
                            <q-tabs
                                v-model="tab" class="text-primary"
                                inline-label
                                mobile-arrows
                                outside-arrows
                            >
                                <q-tab icon="mdi-video-image" label="Vídeo" name="videos"/>
                                <q-tab icon="mdi-image" label="Imagens" name="image"/>
                                <q-tab icon="mdi-panorama" label="Panoramas" name="panorama"/>
                            </q-tabs>
                            <q-tab-panels v-model="tab" >
                                <q-tab-panel name="videos" >
                                    <q-responsive :ratio="16/9" v-if="link_youtube">
                                        <q-video
                                            class="absolute-full"
                                            :src="link_youtube"
                                        />
                                    </q-responsive>
                                    <template v-else>
                                        Vídeo não disponível
                                    </template>
                                </q-tab-panel>
                                <q-tab-panel
                                    class="flex row justify-center" name="image"
                                >
                                    <q-responsive v-if="lote.imagens_lote.length > 0" :ratio="16/14" :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 60vw'">
                                        <q-carousel
                                            v-model="slide"
                                            animated infinite navigation
                                            arrows
                                            control-color="white"
                                            control-text-color="primary"
                                            control-type="push"
                                            next-icon="mdi-chevron-right"
                                            prev-icon="mdi-chevron-left"
                                        >
                                            <q-carousel-slide
                                                v-for="(imagem, key) in (lote.imagens_lote
                                                ? lote.imagens_lote
                                                : [])"
                                                :key="key"
                                                :img-src="imagem?.arquivo.leilaoAbertoUrl.x8"
                                                :name="1+key">
                                            </q-carousel-slide>
                                        </q-carousel>
                                    </q-responsive>
                                    <q-responsive v-else :ratio="16/14" :style="$q.screen.lt.sm ? 'width: 100%' : 'width: 60vw'">
                                        Nenhuma imagem adicionada
                                    </q-responsive>
                                </q-tab-panel>
                                <q-tab-panel name="panorama">
                                    Nenhum panorama adicionado
                                </q-tab-panel>
                            </q-tab-panels>
                        </div>
                    </q-expansion-item>
                    <q-separator/>
                    <q-expansion-item class="text-center bg-grey-2 q-mt-md" label="Local de exposição">
                        <div
                            :class="$q.screen.lt.sm ? 'column q-pa-sm' : 'q-ma-sm'"
                            class="flex"
                        >
                            <div
                                v-if="lote?.leilao?.localidade?.nome && lote?.leilao?.localidade?.nome !== 'ONLINE'"
                                :class="$q.screen.lt.sm ? 'full-width' : ''"
                                style="flex: 1;"
                            >
                                <google-map :nome-local="lote?.leilao?.localidade?.nome"/>
                            </div>
                            <q-separator v-if="lote?.leilao?.localidade?.nome !== 'ONLINE'"/>
                            <div
                                class="full-width flex column q-px-md items-center justify-center"
                                style="flex: 1;"
                            >
                                <h6 class="no-margin no-padding text-center">{{ lote?.leilao?.localidade?.nome }}</h6>
                                <p class="text-center">
                                    {{
                                        lote.leilao.texto_local
                                    }}
                                </p>
                            </div>
                        </div>
                    </q-expansion-item>
                    <q-separator/>
                </q-list>
            </div>
            <habilitacao-extra
                v-model="habilitandoLocal"
                :leilao="lote.leilao"
                :lote="lote"
                :habilitado="habilitado"
                :habilitacoesLeilao="habilitacoesLeilao"
                :habilitacoesExtraLeilao="habilitacoesExtraLeilao"
                :idsHabilitacoesClienteAprovadas="idsHabilitacoesClienteAprovadas"
                :idsHabilitacoesClienteVerificando="idsHabilitacoesClienteVerificando"
                :idsHabilitacoesClienteReprovadas="idsHabilitacoesClienteReprovadas"
                :idsHabilitacoesCliente="idsHabilitacoesCliente"
                :statusHabilitacaoLeilao="statusHabilitacaoLeilao"
                :idsHabilitacoesExtraClienteAprovadas="idsHabilitacoesExtraClienteAprovadas"
                :idsHabilitacoesExtraClienteVerificando="idsHabilitacoesExtraClienteVerificando"
                :idsHabilitacoesExtraClienteReprovadas="idsHabilitacoesExtraClienteReprovadas"
                :idsHabilitacoesExtraCliente="idsHabilitacoesExtraCliente"
                :statusHabilitacaoExtraLeilao="statusHabilitacaoExtraLeilao"
                :documentosRequeridosExtra="documentosRequeridosExtra"
                :documentosRequeridos="documentosRequeridos"/>
            <Lance
                v-model="lanceLocal"
                :leilao="lote.leilao"
                :lote="lote"
                :habilitado="habilitado"
                :automatico="false"
                @atualizacaoLanceAutomatico="atualizarLanceAutomatico"
            />
            <Lance
                v-model="lanceLocalAutomatico"
                :leilao="lote.leilao"
                :lote="lote"
                :habilitado="habilitado"
                :automatico="true"
                :lanceAutomatico="lanceAutomatico"
                :lanceProprio="lanceProprio"
                @atualizacaoLanceAutomatico="atualizarLanceAutomatico"
            />

            <q-dialog v-model="verLances">
                <q-card class="flex no-wrap column" style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
                    <q-toolbar class="bg-primary text-white">
                        <q-icon name="mdi-gavel" />
                        <q-toolbar-title v-if="lote.lances[0]">
                            <template v-if="+lote.leilao.venda_direta === 1">
                                Ofertas
                            </template>
                            <template v-else>
                                Lances
                            </template>
                             no lote "{{lote.titulo}}"
                        </q-toolbar-title>
                        <q-toolbar-title v-else>Sem lances para o lote "{{lote.titulo}}"</q-toolbar-title>
                        <q-btn flat round color="white" icon="mdi-window-close" @click="verLances = false"/>
                    </q-toolbar>
                    <q-list style="max-height: 65vh; overflow-y: auto;">
                        <q-item v-for="(lance, idx) in lote.lances" :key="idx" clickable>
                            <q-item-section avatar class="items-center">
                                <q-icon :color="(idx === 0) ? 'positive' : 'primary'" size="lg" name="mdi-cash"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label v-if="idx === 0" overline>
                                    <template v-if="+lote.leilao.venda_direta === 1">
                                        Proposta Atual
                                    </template>
                                    <template v-else>
                                        Lance Atual
                                    </template>
                                </q-item-label>
                                <q-item-label v-else overline>
                                    <template v-if="+lote.leilao.venda_direta === 1">
                                        Proposta
                                    </template>
                                    <template v-else>
                                        Lance
                                    </template>
                                </q-item-label>
                                <q-item-label  :class="(idx === 0) ? 'text-positive text-h5' : 'text-primary text-h6'">
                                    {{ formatador.format(lance.valor) }}
                                </q-item-label>
                                <q-item-label overline v-if="+lote.leilao.mostrar_apelido === 0">
                                    por <b :class="(lance.cliente.usuario.username === usernameLogado) ? 'text-positive' : 'text-primary'">{{ (lance.cliente.usuario.username === usernameLogado) ? 'Você' : lance.cliente.id }}</b> em <b>{{formatDate(lance.data_hora)}}</b>
                                </q-item-label>
                                <q-item-label overline v-else>
                                    por <b :class="(lance.cliente.usuario.username === usernameLogado) ? 'text-positive' : 'text-primary'">{{lance.cliente.usuario.username}}</b> em <b>{{formatDate(lance.data_hora)}}</b>
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </q-dialog>
            <q-dialog v-model="verDocumentos">
                <q-card class="flex no-wrap column" style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
                    <q-toolbar class="bg-primary text-white">
                        <q-icon name="mdi-gavel" />
                        <q-toolbar-title>Todos os documentos</q-toolbar-title>
                        <q-btn flat round color="white" icon="mdi-window-close" @click="verDocumentos = false"/>
                    </q-toolbar>
                    <q-list style="max-height: 65vh; overflow-y: auto;">

                        <q-item  v-if="outrosDocumentos.length === 0 ">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" size="lg" name="mdi-close-thick"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label overline>Sem anexos</q-item-label>
                                <q-item-label color="primary" class="text-h5">
                                    Nenhum documento encontrado
                                </q-item-label>
                            </q-item-section>
                        </q-item>



                        <q-item v-for="(documento, idx) in outrosDocumentos" :key="idx"
                                clickable
                                @click="baixar(documento.arquivo.signedUrl)">
                            <q-item-section avatar class="items-center">
                                <q-icon color="primary" size="lg" name="mdi-download"/>
                            </q-item-section>

                            <q-item-section>
                                <q-item-label overline>{{ {'0': 'Vistorias', '1': 'Outros'}[documento.tipo] }}</q-item-label>
                                <q-item-label color="primary" class="text-h5">
                                    {{ documento.descricao }}
                                </q-item-label>
                            </q-item-section>
                        </q-item>
                    </q-list>
                </q-card>
            </q-dialog>

        </q-page>
    </LeilaoLayout>
</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 8px
    padding-bottom: 60px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h5
    margin: 0
.nopad > p
    margin: 0
</style>
