<script setup>
import {defineProps, ref, defineEmits } from 'vue';
import useLoteLocalComUpdates from '../../Composables/useLoteLocalComUpdates';

const emit = defineEmits(['selecionado']);

const props = defineProps({
    leilao: Object,
    loteInit: Object,
    loteSelecionado: Object,
    suprimirVendido: Boolean,
    mostrarImagens: Boolean
});

const {
    lote
} = useLoteLocalComUpdates(props)

const abrir = async () => {
    emit('selecionado', lote.value.id);
};
const renderizar = ref(false);
const entrouNaView = (isVisible) => {
    renderizar.value = isVisible;
}

</script>

<template>
    <q-btn
        v-if="lote?.avisos_valor_de_venda[0] "
        v-observe-visibility="{
          callback: entrouNaView,
        }"
        :key="lote.id"
        class="q-ma-xs"
        :unelevated="!(lote.id === loteSelecionado.id)"
        square
        dense
        @click="abrir"
        style="width: 35px; height: 35px"
        :class="lote.id === loteSelecionado.id ? `atual card-${lote.id}` : `card-${lote.id}`"
        :style="`
         transition: all .75s ease-in-out;
         color: white;
         background-color: ${(lote?.avisos_valor_de_venda[0]?.id_lance) ? '#BCCF30' : '#FF733A'};
         transform: ${lote.id === loteSelecionado.id ? 'scale(1.15);' : 'scale(1);'}
         ${lote.id === loteSelecionado.id ? 'border: solid 3px #00000073;' : ''}
         ${lote.id === loteSelecionado.id ? '' : 'opacity: 0.95;'}
         `">
        {{lote.sequencia}}
    </q-btn>
</template>
<style lang="sass" scoped>
.seq-caption
    text-align: center
    padding: 8px
    color: white
    background-color: rgba(0, 0, 0, .3)
.seq-caption > h5
    margin: 0
.nopad > p
    margin: 0
</style>
