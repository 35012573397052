<script setup>
import { defineProps } from 'vue';
import useUtils from '../../Composables/useUtils';
import useLeilao from '../../Composables/useLeilao';
import useGlobals from '../../Composables/useGlobals';
import useLeilaoLocalComUpdates from '../../Composables/useLeilaoLocalComUpdates';

const props = defineProps({
    leilaoInit: Object,
});

const {
    leilao
} = useLeilaoLocalComUpdates(props);

const {
    abrirLeilao,
    abrirLeilaoNovaAba,
    fullscreen,
    // autoplay,
    // slide,
    timer
} = useLeilao(leilao);

const {
    getImagem,
    stripHTML
} = useUtils();


const {
    logoLeiloeiro
} = useGlobals();
</script>

<template>
    <div class="q-pa-sm flex column col">
        <q-card class="cursor-pointer">
            <q-card-section
                :style="`background-color: ${leilao.status_leilao?.cor_site};`"
                style="height: 0.5em"
                class="q-py-none"
                @click="abrirLeilao()" @auxclick="abrirLeilaoNovaAba()">
            </q-card-section>
            <q-card-section
                :style="$q.screen.lt.sm ? 'height: 7em' : 'height: 15em'"
                class="q-pa-none flex row no-wrap"
                @click="abrirLeilao()" @auxclick="abrirLeilaoNovaAba()">
                <q-img
                    :style="$q.screen.lt.sm ? 'width: 7em' : 'width: 15em'"
                    loading="lazy"
                    :src="getImagem((leilao?.imagens_leilao && leilao?.imagens_leilao.length > 0  ? leilao.imagens_leilao : (leilao.comitente.arquivo_capa_padrao ? [{arquivo: leilao.comitente.arquivo_capa_padrao}] : []))[0]?.arquivo, fullscreen, leilao.status_leilao?.identificador)"
                    :ratio="1"
                >
                    <template v-slot:error>
                        <div class="absolute-full flex flex-center bg-primary text-white">
                            Houve um problema carregando a imagem
                        </div>
                    </template>
                    <template v-slot:loading>
                        <div class="absolute-full flex column flex-center bg-white text-primary">
                            <img :src="logoLeiloeiro" style="width: 40%" />
                            <p class="q-mt-sm">
                                <q-spinner size="sm" class="q-pr-sm" /> Carregando imagem...
                            </p>
                        </div>
                    </template>
                </q-img>
                <div class="flex column" style="flex:1; justify-content: space-between;">
                    <div style="flex: 2" class="flex column q-pa-sm">
                        <div
                            :class="$q.screen.lt.sm ? 'text-subtitle2 text-bold' : 'text-subtitle1 text-weight-medium'"
                            class="text-primary q-ma-none"
                            style="text-overflow: ellipsis;
                                   display: -webkit-box;
                                   -webkit-line-clamp: 3;
                                   -webkit-box-orient: vertical;
                                   overflow: hidden;">
                            {{(stripHTML(leilao?.titulo?.toString())?.slice(0, 55) || 'Leilão')}}
                        </div>
                        <div
                            :class="$q.screen.lt.sm ? 'text-small' : 'text-body2'"
                            class="text-grey-10 q-pt-sm">
                            {{(stripHTML(leilao?.descricao?.toString())?.slice(0, 90) || 'Sem Descrição') + (stripHTML(leilao?.descricao?.toString())?.length > 90 ? '...' : '')}}
                        </div>
                    </div>
                    <div v-if="$q.screen.gt.xs" style="flex: 1; height: 4em" class="flex column no-wrap">
                        <q-separator />
                        <div class="flex row no-wrap" style="flex: 1">
                            <div style="flex: 1">
                                <q-tooltip>
                                    Leilão Oficial {{ leilao?.comitente?.nome_fantasia }}
                                </q-tooltip>
                                <q-img
                                    style="height: 3.75em"
                                    :ratio="60/17"
                                    :src="leilao?.comitente?.arquivo?.logoComitenteUrl"
                                    fit="contain">
                                </q-img>
                            </div>
                            <q-separator vertical/>



                            <div v-if="+leilao.venda_direta === 1" class="flex column justify-center" style="flex: 1">
                                <p
                                    style="font-size: 14px"
                                    class="text-center q-ma-none"
                                    :class="'text-' + leilao.proximoStatus.cor">
                                    Aceitando
                                </p>
                                <p
                                    class="text-center text-bold q-ma-none"
                                    :class="'text-' + leilao.proximoStatus.cor">
                                    Propostas
                                </p>
                            </div>
                            <div v-else class="flex column justify-center" style="flex: 1">
                                <p
                                    style="font-size: 14px"
                                    class="text-center q-ma-none"
                                    :class="'text-' + leilao.proximoStatus.cor">
                                    {{ leilao.proximoStatus.texto }}
                                </p>
                                <p  v-if="leilao.status_leilao?.identificador !== 'ENCERRADO'"
                                    class="text-center text-bold q-ma-none"
                                    :class="'text-' + leilao.proximoStatus.cor">
                                    {{ timer }}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </q-card-section>
            <div v-if="$q.screen.lt.sm" style="flex: 1; height: 4em" class="flex column no-wrap">
                <q-separator />
                <div class="flex row no-wrap" style="flex: 1">
                    <div style="flex: 1">
                        <q-tooltip>
                            Leilão Oficial {{ leilao?.comitente?.nome_fantasia }}
                        </q-tooltip>
                        <q-img
                            style="height: 3.75em"
                            :ratio="60/17"
                            :src="leilao?.comitente?.arquivo?.logoComitenteUrl"
                            fit="contain">
                        </q-img>
                    </div>
                    <q-separator vertical/>



                    <div v-if="+leilao.venda_direta === 1" class="flex column justify-center" style="flex: 1">
                        <p
                            style="font-size: 14px"
                            class="text-center q-ma-none"
                            :class="'text-' + leilao.proximoStatus.cor">
                            Aceitando
                        </p>
                        <p
                            class="text-center text-bold q-ma-none"
                            :class="'text-' + leilao.proximoStatus.cor">
                            Propostas
                        </p>
                    </div>
                    <div v-else class="flex column justify-center" style="flex: 1">
                        <p
                            style="font-size: 14px"
                            class="text-center q-ma-none"
                            :class="'text-' + leilao.proximoStatus.cor">
                            {{ leilao.proximoStatus.texto }}
                        </p>
                        <p
                            class="text-center text-bold q-ma-none"
                            :class="'text-' + leilao.proximoStatus.cor">
                            {{ timer }}
                        </p>
                    </div>
                </div>
            </div>
        </q-card>
    </div>
</template>
