<script setup>
import { defineProps, computed} from 'vue';
import CardLoteArrematado from './CardLoteArrematado';
import CardFatura from './CardFatura';

const props = defineProps({
    lotes: Object,
});

const faturas = computed(() => {

    const fats = {};
    if (!props.lotes || props.lotes?.length === 0){
        return [];
    }
    props.lotes?.forEach((lote) => {
        const arremateFatura = lote.lances[0]?.arremates[0]?.arrematesfatura[0];
        const fatura = arremateFatura?.fatura;
        const idFatura = fatura?.id;
        if (!arremateFatura){
            if(!fats[-1]) {
                fats[-1] = {
                    id: null,
                    dados: null,
                    lotes: []
                }
            }
            fats[-1].lotes.push(lote);

        } else {

            if (!fats[idFatura]) {
                fats[idFatura] = {
                    id: idFatura,
                    dados: fatura,
                    lotes: []
                }
            }
            fats[idFatura].lotes.push(lote);
        }
    })

    //console.log(fats);

    const semFatura = fats[-1];

    fats[-1] = null;

    return [ ...Object.values(fats).filter(i => !!i), semFatura ];
});

</script>

<template>
    <div class="flex row full-width q-pa-sm">
        <card-fatura :key="idx" :fatura="fatura" v-for="(fatura, idx) in faturas">
            <card-lote-arrematado :key="lote.id" :index="index" v-for="(lote, index) in fatura.lotes || []" :lote-init="lote" :leilao-init="lote.leilao" :mostrar-leilao="true" />
        </card-fatura>
    </div>
</template>
