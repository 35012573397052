<script setup>
import { Head, useForm, usePage } from '@inertiajs/inertia-vue3';
import MainLayout from '../../../Layouts/MainLayout';
import useRotas from '../../../Composables/useRotas';
import { useQuasar } from 'quasar';
import FormRow from '../../../Componentes/Forms/FormRow';
import FormInput from '../../../Componentes/Forms/FormInput';
import FormGroupLabel from '../../../Componentes/Forms/FormGroupLabel';
import { cpf } from 'cpf-cnpj-validator';
import isEmail from 'validator/lib/isEmail';
import {computed, defineProps, onMounted, ref} from 'vue';
//import useUtils from '../../../Composables/useUtils';
import moment from "moment/moment";

const $q = useQuasar();
const route = useRotas();

const props = defineProps({
    user: Object,
});

const listaEstadoUF = ref([]);
const listaInteresses = ref([]);

const atualizarListaEstadoUF = async () => {
    const response = await fetch(route('utilidades.listaEstadoUF'), {
        method: 'GET',
        credentials: 'include'
    });

    const lista = await response.json();

    lista.forEach(estado => {
        listaEstadoUF.value.push(estado);
    });

}

const atualizarListaInteresses = async () => {
    const response = await fetch(route('utilidades.listaInteresses'), {
        method: 'GET',
        credentials: 'include'
    });

    const lista = await response.json();

    lista.forEach(interesse => {
        listaInteresses.value.push(interesse);
    });

}

onMounted(async() =>{
    await atualizarListaEstadoUF();
    await atualizarListaInteresses();

    // pega o celular da tabela de usuarios, caso não tiver, tenta encontrar um na tabela de telefones que seja celular
    form.celular = (usePage().props.value.auth?.usuario?.telefone_celular) ? usePage().props.value.auth?.usuario?.telefone_celular :
                    (props.user?.telefones_cliente?.filter((item) => item.ordem === '1' && item.nome === 'Celular').length > 0) ?
                        props.user?.telefones_cliente?.filter((item) => item.ordem === '1' && item.nome === 'Celular')[0]['telefone']
                        : '';

    if(props.user.enderecos_cliente?.[0]?.uf_endereco){
        form.estado = props.user.enderecos_cliente?.[0]?.uf_endereco;
        form.cidade = props.user.enderecos_cliente?.[0]?.cidade_endereco;
        form.bairro = props.user.enderecos_cliente?.[0]?.bairro_endereco;
        form.endereco = props.user.enderecos_cliente?.[0]?.rua_endereco;
    }else{
        form.estado = props.user.enderecos_cliente?.[0]?.rua?.bairro?.cidade?.estado?.codigo_uf;
        form.cidade = props.user.enderecos_cliente?.[0]?.rua?.bairro?.cidade?.nome;
        form.bairro = props.user.enderecos_cliente?.[0]?.rua?.bairro?.nome;
        form.endereco = props.user.enderecos_cliente?.[0]?.rua?.tipo + ' ' + props.user.enderecos_cliente?.[0]?.rua?.nome;
    }

});


const form = useForm({
    id: props.user?.id,
    nomeUsuario:  usePage().props.value.auth.usuario.username, // 'Teste Novo',
    senha: null, // '123456789Aa@',
    nomeCompleto: props.user?.nome_completo, // 'Teste Novo',
    cpf: props.user?.cpf_cnpj, // '04884383060',
    rg: props.user?.rg_ie, // '560398301',
    orgaoEmissorRg: props.user?.orgao_emissor_rg, // 'Teste Novo',
    nacionalidade: +props.user?.nacionalidade, // 0,
    sexo: +props.user?.sexo, // 0,
    dataDeNascimento: moment(props.user?.data_de_nascimento, 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYY'), // '07/12/2000',
    profissao: props.user?.profissao, // 'Teste Novo',
    nomeDaMae: props.user?.nome_mae, // 'Teste Novo',
    nomeDoPai: props.user?.nome_pai, // 'Teste Novo',
    email: props.user?.email, // 'teste@teste.com',
    celular: null,
    telefone:       (props.user?.telefones_cliente?.filter((item) => item.ordem === '1' && item.nome === 'Residencial').length > 0) ? props.user?.telefones_cliente?.filter((item) => item.ordem === '1' && item.nome === 'Residencial')[0]['telefone'] : '', // '11954125910',
    telefoneRecado: (props.user?.telefones_cliente?.filter((item) => item.ordem === '1' && item.nome === 'Recados').length > 0) ? props.user?.telefones_cliente?.filter((item) => item.ordem === '1' && item.nome === 'Recados')[0]['telefone'] : '', // '11954125910',
    estadoCivil: +props.user?.estado_civil, // 1,
    regimeCasamento: isNaN(+props.user?.dados_conjuge_cliente?.[0]?.regime_casamento) ? '' : +props.user?.dados_conjuge_cliente?.[0]?.regime_casamento, // 0,
    dataCasamento: moment(props.user?.dados_conjuge_cliente?.[0]?.data_casamento, 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYY'), // '18/04/2022',
    dataDeNascimentoConjuge: moment(props.user?.dados_conjuge_cliente?.[0]?.data_nascimento, 'YYYY-MM-DD HH:mm:ss').format('DDMMYYYY'), // '18/04/2022',
    nomeConjuge: props.user?.dados_conjuge_cliente?.[0]?.nome_conjuge, // 'Teste Novo',
    rgConjuge: props.user?.dados_conjuge_cliente?.[0]?.rg_conjuge, // '56039830X',
    orgaoEmissorRgConjuge: props.user?.dados_conjuge_cliente?.[0]?.ssp_conjuge,
    cpfConjuge: props.user?.dados_conjuge_cliente?.[0]?.cpf_conjuge, // '46283413897',
    profissaoConjuge: props.user?.dados_conjuge_cliente?.[0]?.profissao_conjuge, // 'Teste Novo',
    nacionalidadeConjuge: isNaN(+props.user?.dados_conjuge_cliente?.[0]?.nacionalidade_conjuge) ? '' : +props.user?.dados_conjuge_cliente?.[0]?.nacionalidade_conjuge, // 0,
    cep: (props.user.enderecos_cliente?.[0]?.cep_rua).padStart(8, '0'), // '09667000',
    estado: null, // 'Teste Novo',
    cidade: null, // 'Teste Novo',
    bairro: null, // 'Teste Novo',
    endereco: null, // 'Teste Novo',
    numero: props.user.enderecos_cliente?.[0]?.numero, // 'Teste Novo',
    complemento: props.user.enderecos_cliente?.[0]?.complemento_endereco, // 'Teste Novo',
    referencia: props.user.enderecos_cliente?.[0]?.observacao_endereco, // 'Teste Novo',
    referenciaBancaria: null, // 'Teste Novo',
    informacoesExtras: props.user?.dados_bancarios_cliente?.[0]?.informacoes_extras, // 'Teste Novo',
    interesses: props.user?.interesses_cliente.map((i)=> +i.id_interesse) || [],
})

const campos = {
    tituloAcesso: {
        tipo: 'titulo',
        texto: 'Dados de Acesso'
    },
    nomeUsuario: {
        tipo: 'campo',
        largura: 6,
        label: 'Apelido',
        dica: 'Este será o nome que aparecerá no site para outros usuários, não pode ser um email',
        autocomplete: 'username',
        icon: 'mdi-account',
        required: true,
        regras: [
            val => val && !/(.com|@)/.test(val) || 'Este campo não pode ser um email.',
            val => val && !isEmail(val) || 'Este campo não pode ser um email',
            val => val && val.length > 5 || 'Deve ter mais de 5 caracteres'
        ]
    },
    /*senha: {
        tipo: 'campo',
        largura: 6,
        label: 'Senha',
        type: 'password',
        autocomplete: 'password',
        icon: 'mdi-key-variant',
        required: true,
        regras: [
            val => val && val.length >= 6 || 'Senha muito curta, ela deve ter 6 ou mais caracteres',
            val => val && /^(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$&*])/.test(val) || 'A senha deve ter ao menos uma letra minuscula, uma maiuscula e um caractere especial'
        ]
    },*/
    tituloPessoais: {
        tipo: 'titulo',
        texto: 'Dados Pessoais'
    },
    nomeCompleto: {
        tipo: 'campo',
        largura: 6,
        label: 'Nome Completo',
        autocomplete: 'name',
        icon: 'mdi-account',
        required: true,
        regras: []
    },
    cpf: {
        tipo: 'campo',
        largura: 6,
        label: 'CPF',
        dica: 'Este campo não pode ser alterado',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        readonly: true,
        mascara: '###.###.###-##',
        regras: [
            val => val && val.length === 11 || 'Por favor, digite o seu CPF igual está no documento.',
            val => val && cpf.isValid(val) || 'Este CPF parece estar incorreto, por favor, verifique para erros de digitação.',
        ]
    },
    rg: {
        tipo: 'campo',
        largura: 6,
        label: 'RG',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        maxlength: 25,
        regras: []
    },
    orgaoEmissorRg: {
        tipo: 'campo',
        largura: 6,
        label: 'Orgão Expeditor RG',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        regras: []
    },
    nacionalidade: {
        tipo: 'campo',
        opcoes: [
            {
                label: 'Brasileiro',
                value: 0,
            },
            {
                label: 'Estrangeiro',
                value: 1,
            },
        ],
        largura: 6,
        label: 'Nacionalidade',
        autocomplete: 'off',
        icon: 'mdi-flag-variant',
        required: true,
        regras: []
    },
    sexo: {
        tipo: 'campo',
        largura: 6,
        label: 'Sexo',
        autocomplete: 'off',
        icon: 'mdi-gender-male-female',
        required: true,
        regras: [],
        opcoes: [
            {
                label: 'Masculino',
                value: 0,
            },
            {
                label: 'Feminino',
                value: 1,
            },
            {
                label: 'Não Informado',
                value: 2,
            },
        ],

    },
    dataDeNascimento: {
        tipo: 'campo',
        largura: 6,
        label: 'Data de nascimento',
        autocomplete: 'bday',
        data: true,
        optionsFn: (date) =>{
            return new Date(date) < new Date((new Date()).getTime()-(24*60*60*1000*365*18));
        },
        dataDefaultYM: `${new Date((new Date()).getTime()-(24*60*60*1000*365*18)).getFullYear()}/${new Date((new Date()).getTime()-(24*60*60*1000*365*18)).getMonth()+1}`,
        icon: 'mdi-cake',
        required: true,
        mascara: '##/##/####',
        regras: []
    },
    profissao: {
        tipo: 'campo',
        largura: 6,
        label: 'Profissão',
        autocomplete: 'off',
        icon: 'mdi-briefcase',
        required: true,
        regras: []
    },
    nomeDaMae: {
        tipo: 'campo',
        largura: 6,
        label: 'Nome da mãe',
        autocomplete: 'off',
        icon: 'mdi-human-female',
        required: true,
        regras: []
    },
    nomeDoPai: {
        tipo: 'campo',
        largura: 6,
        label: 'Nome do pai',
        autocomplete: 'off',
        icon: 'mdi-human-male',
        required: false,
        regras: []
    },
    email: {
        tipo: 'campo',
        largura: 6,
        label: 'Email',
        dica: 'Alterando este campo você deverá realizar uma verificação clicando no link do email que será enviado.',
        autocomplete: 'email',
        icon: 'mdi-email',
        required: true,
        regras: [
            val => val && isEmail(val) || 'Por favor, preencha um email valido',
        ]
    },
    celular: {
        tipo: 'campo',
        largura: 6,
        label: 'DDD + Celular',
        dica: 'Alterando este campo você deverá realizar uma verificação digitando o SMS enviado.',
        autocomplete: 'off',
        icon: 'mdi-cellphone',
        required: true,
        regras: [
            val => ![
                '11 11111-1111',
                '22 22222-2222',
                '33 33333-3333',
                '44 44444-4444',
                '55 55555-5555',
                '66 66666-6666',
                '77 77777-7777',
                '88 88888-8888',
                '99 99999-9999',
                '12 34567-8910',
                '11 12345-6789',
                '12 33214-5665',
                '12 34567-8912'
            ].includes(val) || 'Telefone Inválido',
        ],
        mascara: '## #####-####'
    },
    telefone: {
        tipo: 'campo',
        largura: 6,
        label: 'DDD + Telefone Fixo',
        autocomplete: 'off',
        icon: 'mdi-phone',
        required: false,
        regras: [
            val => ![
                '11 1111-1111',
                '22 2222-2222',
                '33 3333-3333',
                '44 4444-4444',
                '55 5555-5555',
                '66 6666-6666',
                '77 7777-7777',
                '88 8888-8888',
                '99 9999-9999',
                '12 3456-8910',
                '11 1234-6789',
                '12 3321-5665',
                '12 3456-8912'
            ].includes(val) || 'Telefone Inválido',
        ],
        mascara: '## ####-####'
    },
    telefoneRecado: {
        tipo: 'campo',
        largura: 6,
        label: 'DDD + Telefone para recado',
        autocomplete: 'off',
        icon: 'mdi-phone-in-talk',
        required: false,
        regras: [
            val => ![
                '11 11111-1111',
                '22 22222-2222',
                '33 33333-3333',
                '44 44444-4444',
                '55 55555-5555',
                '66 66666-6666',
                '77 77777-7777',
                '88 88888-8888',
                '99 99999-9999',
                '12 34567-8910',
                '11 12345-6789',
                '12 33214-5665',
                '12 34567-8912'
            ].includes(val) || 'Telefone Inválido',
        ],
        mascara: '## #####-####'
    },
    estadoCivil: {
        tipo: 'campo',
        largura: 6,
        label: 'Estado Civil',
        autocomplete: 'off',
        icon: 'mdi-ring',
        required: true,
        opcoes: [
            {
                label: 'Solteiro(a)',
                value: 0,
            },
            {
                label: 'Casado(a)',
                value: 1,
            },
            {
                label: 'Divorciado(a)',
                value: 2,
            },
            {
                label: 'Viuvo(a)',
                value: 3,
            },
            {
                label: 'União Estavel',
                value: 4,
            },
        ]
    },
    tituloCasamento: {
        tipo: 'titulo',
        texto: 'Dados Conjuge',
        condicional: computed(() => form.estadoCivil === 1)
    },
    regimeCasamento: {
        tipo: 'campo',
        largura: 6,
        label: 'Regime de casamento',
        autocomplete: 'off',
        icon: 'mdi-script',
        required: true,
        opcoes : [
            {
                label: 'Comunhão Parcial de Bens',
                value: 0,
            },
            {
                label: 'Comunhão Universal de Bens',
                value: 1,
            },
            {
                label: 'Separação Convencional de Bens',
                value: 2,
            },
            {
                label: 'Separação Obrigatória de Bens',
                value: 3,
            },
            {
                label: 'Participação final nos Aquestos',
                value: 4,
            },
        ],
        condicional: computed(() => form.estadoCivil === 1)
    },
    dataCasamento: {
        tipo: 'campo',
        largura: 6,
        label: 'Data de casamento',
        autocomplete: 'off',
        icon: 'mdi-cake',
        optionsFn: (date) =>{
            return new Date(date) < new Date();
        },
        required: true,
        data: true,
        mascara: '##/##/####',
        regras: [],
        condicional: computed(() => form.estadoCivil === 1)
    },
    dataDeNascimentoConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'Data de nascimento do Conjuge',
        autocomplete: 'off',
        icon: 'mdi-cake',
        optionsFn: (date) =>{
            return new Date(date) < new Date();
        },
        required: true,
        data: true,
        mascara: '##/##/####',
        regras: [],
        condicional: computed(() => form.estadoCivil === 1)
    },
    nomeConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'Nome do cônjugue',
        autocomplete: 'off',
        icon: 'mdi-account-supervisor',
        required: true,
        regras: [
        ],
        condicional: computed(() => form.estadoCivil === 1)
    },
    rgConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'RG do cônjugue',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        maxlength: 25,
        regras: [],
        condicional: computed(() => form.estadoCivil === 1)
    },
    orgaoEmissorRgConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'Orgão Expeditor RG do conjuge',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        regras: [],
        condicional: computed(() => form.estadoCivil === 1)
    },
    cpfConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'CPF do cônjugue',
        autocomplete: 'off',
        icon: 'mdi-card-account-details',
        required: true,
        mascara: '###.###.###-##',
        regras: [
            val => val && val.length === 11 || 'Por favor, digite o seu CPF igual está no documento.',
            val => val && cpf.isValid(val) || 'Este CPF parece estar incorreto, por favor, verifique para erros de digitação.',
        ],
        condicional: computed(() => form.estadoCivil === 1)
    },
    profissaoConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'Profissao do cônjugue',
        autocomplete: 'off',
        icon: 'mdi-briefcase',
        required: true,
        regras: [],
        condicional: computed(() => form.estadoCivil === 1)
    },
    nacionalidadeConjuge: {
        tipo: 'campo',
        largura: 6,
        label: 'Nacionalidade do cônjuge',
        autocomplete: 'off',
        icon: 'mdi-flag-variant',
        required: true,
        opcoes: [
            {
                label: 'Brasileiro',
                value: 0,
            },
            {
                label: 'Estrangeiro',
                value: 1,
            },
        ],
        condicional: computed(() => form.estadoCivil === 1)
    },
    tituloEndereco: {
        tipo: 'titulo',
        texto: 'Endereço'
    },
    cep: {
        tipo: 'campo',
        largura: 6,
        label: 'CEP',
        autocomplete: 'postcode',
        icon: 'mdi-map-search',
        required: true,
        mascara: '#####-###',
        regras: [
            val => val && val.length >= 5 || 'CEP Inválido',
        ],
        onChange: async () => {
            if (form.cep.length > 6){
                let resultado;
                try {
                    $q.loading.show({
                        message: 'Procurando seu CEP...'
                    });
                    resultado = await (await fetch(route('utilidades.consultaCep', {cep: form.cep}), {
                        method: 'GET',
                        credentials: 'include'
                    })).json();

                    $q.loading.hide();
                    form.endereco = (resultado.nome) ? `${resultado.tipo} ${resultado.nome}` : '';
                    form.bairro = resultado.bairro.nome;
                    form.cidade = resultado.bairro.cidade.nome;
                    //form.estado = resultado.bairro.cidade.estado.nome;
                    form.estado = resultado.bairro.cidade.estado.codigo_uf;
                } catch (e) {
                    $q.notify({
                        icon: 'mdi-map-marker-remove',
                        message: 'O CEP fornecido não foi encontrado',
                        color: 'negative'
                    })
                }
            }
        }
    },
    estado: {
        tipo: 'campo',
        largura: 6,
        label: 'Estado',
        autocomplete: 'off',
        icon: 'mdi-map',
        required: true,
        opcoes: listaEstadoUF.value,
        readonly: false,
        regras: []
    },
    cidade: {
        tipo: 'campo',
        largura: 6,
        label: 'Cidade',
        autocomplete: 'off',
        icon: 'mdi-city-variant',
        required: true,
        readonly: false,
        regras: [
            val => val && val.length <= 100 || 'Cidade deve ser abreviada',
        ]
    },
    bairro: {
        tipo: 'campo',
        largura: 6,
        label: 'Bairro',
        autocomplete: 'off',
        icon: 'mdi-home-map-marker',
        required: true,
        readonly: false,
        regras: [
            val => val && val.length <= 100 || 'Bairro deve ser abreviado',
        ]
    },
    endereco: {
        tipo: 'campo',
        largura: 4,
        label: 'Rua',
        autocomplete: 'off',
        icon: 'mdi-map-marker-multiple',
        required: true,
        regras: [
            val => val && val.length <= 150 || 'Endereço deve ser abreviado',
        ]
    },
    numero: {
        tipo: 'campo',
        largura: 4,
        label: 'Número',
        autocomplete: 'off',
        icon: 'mdi-pin',
        required: true,
        regras: []
    },
    complemento: {
        tipo: 'campo',
        largura: 4,
        label: 'Complemento',
        autocomplete: 'off',
        icon: 'mdi-pin',
        required: false,
        regras: []
    },
    tituloInteresses: {
        tipo: 'titulo',
        texto: 'Interesses'
    },
    interesses: {
        tipo: 'checkbox',
        largura: 6,
        label: 'Interesses',
        autocomplete: 'off',
        icon: 'mdi-map',
        required: true,
        opcoes: listaInteresses.value,
        readonly: false
    },
    tituloExtra: {
        tipo: 'titulo',
        texto: 'Informações Extra'
    },
    informacoesExtras: {
        tipo: 'campo',
        largura: 12,
        label: 'Informações Extras',
        autocomplete: 'password',
        icon: 'mdi-file-document-multiple-outline',
        required: false,
        regras: []
    },
};
</script>

<template>
    <Head title="Meus Dados"/>
    <MainLayout>
        <q-page class="flex row justify-center items-start q-py-md">
            <div class="flex column items-center col-11">
                <h4
                    class="text-center text-primary font-weight-light q-ma-md q-mb-lg"
                    :class="$q.screen.gt.sm ? 'text-h4' : 'text-h5'">
                    Editar Meus Dados
                </h4>
                <q-form class="full-width" @submit="form.post(route('minha_conta.fisica.atualizar'))">
                    <form-row>
                        <template :key="indice" v-for="(config, campo, indice) in campos">
                            <template v-if="config.condicional === undefined || config.condicional.value">
                                <template v-if="config.tipo === 'titulo'">
                                    <form-group-label :text="config.texto" />
                                </template>
                                <template v-if="config.tipo === 'checkbox'">
                                    <q-field
                                        v-model="form[campo]"
                                        borderless
                                        dense
                                        :rules="[ val => +val?.length > 0 || '']"
                                    >
                                        <q-option-group
                                            v-model="form[campo]"
                                            :options="config.opcoes"
                                            color="primary"
                                            type="checkbox"
                                            inline
                                            class="q-mx-none q-mb-md full-width"
                                            size="lg"
                                        />
                                    </q-field>
                                </template>
                                <template v-if="config.tipo === 'campo'">
                                    <form-input
                                        v-model="form[campo]"
                                        :dica="config.dica"
                                        :autocomplete="config.autocomplete"
                                        :label="config.label"
                                        :rules="config.regras"
                                        :type="config.type"
                                        :icon="config.icon"
                                        :required="config.required"
                                        :largura="config.largura"
                                        :mascara="config.mascara"
                                        :opcoes="config.opcoes"
                                        :data="config.data"
                                        :readonly="config.readonly"
                                        :optionsFn="config.optionsFn"
                                        :dataDefaultYM="config.dataDefaultYM"
                                        :maxlength="config.maxlength"
                                        @change="config.onChange"
                                    />
                                </template>
                            </template>
                        </template>
                    </form-row>
                    <q-btn
                        class="full-width"
                        color="primary"
                        label="Atualizar Meus Dados"
                        icon-right="mdi-arrow-right"
                        type="submit"
                        :loading="form.processing"
                    />
                </q-form>
            </div>
        </q-page>
    </MainLayout>
</template>
