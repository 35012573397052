<script setup>
import {computed, defineProps, ref} from 'vue';
import useUtils from '../../Composables/useUtils';

const props = defineProps({
    fatura: Object,
});

const {
    formatador,
    formatDateWithoutHours,
    baixar,
} = useUtils();


const doulheTresConfig = computed(() => window.doulheTresConfig);

const verOrientacoes = ref(false);

const faturado = !!(props.fatura?.dados);
const pago = !(['0']).includes((props.fatura?.dados?.id_status_fatura)??'0');

const statusFatura =  ref(props.fatura?.dados?.statusFatura);
const idStatusFatura =  ref(props.fatura?.dados?.id_status_fatura);

const urlArquivo = ref(props.fatura?.dados?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.arquivo?.signedUrl);
const orientacaoPagamentoPix = ref(props.fatura?.dados?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.orientacoes_de_pagamento_pix || props.fatura?.lotes[0]?.leilao?.orientacoes_de_pagamento_pix);
const orientacaoPagamentoTransfBancaria = ref(props.fatura?.dados?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.orientacoes_de_pagamento_transf_bancaria || props.fatura?.lotes[0]?.leilao?.orientacoes_de_pagamento_transf_bancaria);

const idTipoPagamento = ref(props.fatura?.dados?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.id_tipo_pagamento);
const tipoPagamento = ref(props.fatura?.dados?.fatura_parcelas[0]?.fatura_parcela_transacoes[0]?.tipoPagamento);


const valorComissao = ref(props.fatura?.dados?.valor_comissao);
const valorTotal = ref(props.fatura?.dados?.valor_total_fatura);
const valorTaxaAdministrativa = ref(props.fatura?.dados?.valor_taxa_administrativa);
const valorLance = valorTotal.value - valorComissao.value - valorTaxaAdministrativa.value;

</script>

<template>



    <div class="q-pa-sm col col-12">
        <q-list bordered class="rounded-borders">

            <q-expansion-item :hide-expand-icon="$q.screen.lt.md">

                <template v-slot:header="{ expanded }" >
                    <div class="full-width" :class="$q.screen.lt.md ? '' : 'flex'">
                        <q-item-section avatar

                                        :class="pago ? 'bg-positive' : (+idStatusFatura === 0) ? 'bg-warning' : 'bg-primary'"
                                        style="padding: 15px 0 15px 0"
                                        :style="$q.screen.lt.md ? '' : 'min-width: 250px;'">
                            <div class="full-width" style="color:white; text-align: center"
                                 v-if="faturado"
                                 :style="$q.screen.lt.md ? 'font-size: 15px;' : 'font-size: 20px;'">Fatura nº {{fatura?.dados?.id }}
                            </div>
                            <div class="full-width" style="color:white; text-align: center"
                                 v-else
                                 :style="$q.screen.lt.md ? 'font-size: 15px;' : 'font-size: 20px;'">Em
                            </div>
                            <div class="full-width" style="color:white; text-align: center; font-weight: bold;"
                                 v-if="faturado"
                                 :style="$q.screen.lt.md ? 'font-size: 16px;' : 'font-size: 18px;'">{{statusFatura}}
                            </div>
                            <div class="full-width" style="color:white; text-align: center; font-weight: bold;"
                                 v-else
                                 :style="$q.screen.lt.md ? 'font-size: 16px;' : 'font-size: 18px;'">Análise
                            </div>
                        </q-item-section>

                        <q-item-section :class="$q.screen.lt.md ? '' : 'q-pl-lg' ">
                            <div style="font-size: 15px;"
                                 :style="$q.screen.lt.md ? 'text-align: center; padding: 10px 0 10px 0; line-height:2.5' : 'line-height:2.0' ">
                                <div ><q-icon name="mdi-gavel" class="text-primary" size="24px" /> Lotes Arrematados: {{fatura?.lotes?.length}}</div>
                                <div v-if="+idTipoPagamento"><q-icon name="mdi-currency-usd" class="text-primary" size="24px" /> Método de Pagamento: {{tipoPagamento}} </div>
                                <div v-if="faturado"><q-icon name="mdi-calendar" class="text-primary" size="24px" /> Data de Vencimento: {{formatDateWithoutHours(fatura?.dados?.fatura_parcelas[0]?.data_vencimento)}}</div>
                                <div v-else>Seus lotes estão em análise para disponibilização da fatura.</div>
                            </div>
                        </q-item-section>

                        <q-separator/>

                        <q-item-section style="margin-left: 0;"
                                        :style="$q.screen.lt.md ? '' : 'margin-right: 30px'"
                                        :side="$q.screen.gt.sm"
                        >
                            <div style=" text-align: center; font-weight: bold;"
                                 :class="pago ? 'text-positive' : 'text-primary'"
                                 v-if="faturado"
                                 :style="$q.screen.lt.md ? 'font-size: 20px;  padding: 10px 0 10px 0; ' : 'font-size: 26px; text-align:right;'"
                                 class="full-width">
                                <div :style="$q.screen.lt.md ? '' : 'text-align:right;'" style="font-size: 14px; font-weight: 300;" :class="pago ? 'text-grey-8' : ''">Valor dos Lances: {{formatador.format(valorLance)}} +</div>
                                <div :style="$q.screen.lt.md ? '' : 'text-align:right;'" style="font-size: 14px; font-weight: 300;" :class="pago ? 'text-grey-8' : ''">Comissão do Leiloeiro: {{formatador.format(valorComissao)}} +</div>
                                <div :style="$q.screen.lt.md ? '' : 'text-align:right;'" style="font-size: 14px; font-weight: 300;" :class="pago ? 'text-grey-8' : ''">Despesa Administrativa: {{formatador.format(valorTaxaAdministrativa)}} +</div>
                                <q-icon name="mdi-cash"  size="30px" />
                                {{formatador.format(valorTotal) }}

                                <div style="font-size: 14px; font-weight: 300;" :class="pago ? 'text-grey-8' : ''" v-if="pago">Clique para ver os detalhes</div>
                                <div style="font-size: 14px; font-weight: 300;" v-else>Clique para exibir detalhes</div>

                            </div>
                        </q-item-section>

                        <q-separator/>

                        <q-item-section v-if="faturado && !pago"
                                        :class="$q.screen.lt.md ? '' : 'q-pl-lg' " style="max-width: 200px; margin-right: 30px; text-align: center;">

                            <q-btn v-if="+idTipoPagamento === 1"
                                   style="min-height: 60px; font-size:10px;"
                                   :class="'bg-primary'"
                                   class="text-white hide-print"
                                   label="Realizar Pagamento"
                                   icon="mdi-currency-usd"
                                   @click.stop="baixar(urlArquivo)"
                            />

                            <q-btn v-else
                                   style="min-height: 60px; font-size:10px;"
                                   :class="'bg-primary'"
                                   class="text-white hide-print"
                                   label="Realizar Pagamento"
                                   icon="mdi-currency-usd"
                                   @click.stop="verOrientacoes=true"
                            />


                        </q-item-section>

                        <q-separator/>

                        <q-item-section style="margin-left: 0;" :side="$q.screen.gt.sm" v-if="$q.screen.lt.md">
                            <div style="font-size: 15px; text-align: center" class="text-primary full-width">
                                <template v-if="expanded">
                                    <q-icon name="mdi-chevron-up"></q-icon>
                                </template>
                                <template v-else>
                                    <q-icon name="mdi-chevron-down"></q-icon>
                                </template>
                            </div>
                        </q-item-section>

                    </div>
                </template>

                <div class="q-pa-sm col col-12"
                     style="justify-content: stretch"
                >

                    <slot/>

                </div>

            </q-expansion-item>

            <q-separator />

        </q-list>
    </div>

    <q-dialog v-model="verOrientacoes">
        <q-card class="flex no-wrap column" style="min-width: 30vw; overflow-y: auto;overflow-x: hidden">
            <q-toolbar class="bg-primary text-white">
                <q-icon name="mdi-cash" />
                <q-toolbar-title>
                    Orientações para Pagamento
                </q-toolbar-title>
                <q-btn flat round color="white" icon="mdi-window-close" @click="verOrientacoes = false"/>
            </q-toolbar>

            <div v-if="+idTipoPagamento===2" class="q-pa-md" v-html="orientacaoPagamentoPix"/>
            <div v-else-if="+idTipoPagamento===3" class="q-pa-md" v-html="orientacaoPagamentoTransfBancaria"/>

            <div v-else class="q-pa-md">Entre em contato para maiores informações.</div>
            <div class="q-pa-md">Telefone para contato: {{doulheTresConfig?.telefone_contato}}</div>
        </q-card>
    </q-dialog>

</template>
